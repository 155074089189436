<template>
  <main class="home">
    <section class="progress">
      <p class="stage">3</p>
      <div class="bar"><span></span></div>
      <p class="max">4</p>
    </section>
    <section class="header">
      <h1>Set new standards</h1>
      <p>Take advantage using Tjing’s revolutionary features (optional)</p>
    </section>
    <section class="features">
      <div class="verifier">
        <FeatureVerifier class="feature-icon" />
        <h3>The Verifier™</h3>
        <p>Effectively reduces the chance of incorrect scores.</p>
        <div class="links">
          <a href="https://www.youtube.com/watch?v=8--3SXTUuLk" target="_blank"
            >Watch video</a
          >
          <a href="https://support.tjing.se/verifier" target="_blank"
            >Learn more</a
          >
        </div>

        <DefaultToggle size="large" v-model="leagueSettings.isVerifyMode" />
        <div class="foot">Mandatory for PDGA events</div>
      </div>
      <div class="signature">
        <FeatureSignature class="feature-icon" />
        <h3>The Signature™</h3>
        <p>A four digit PIN for players to digitally sign their results.</p>
        <div class="links">
          <a href="https://www.youtube.com/watch?v=XSkxigU_AIY" target="_blank"
            >Watch video</a
          >
          <a href="https://support.tjing.se/signature" target="_blank"
            >Learn more</a
          >
        </div>

        <DefaultToggle
          size="large"
          v-model="leagueSettings.isResultSigningEnabled"
        />
        <div class="foot">Mandatory for PDGA events</div>
      </div>
      <div
        class="payments"
        id="payments"
        :class="{ error: erronousSections.includes('payments') }"
      >
        <FeaturePayment class="feature-icon-small" />
        <h3>Direct payments</h3>
        <p>A complete integrated payment solution for player registrations.</p>
        <div class="links">
          <a href="https://support.tjing.se/directpayments" target="_blank"
            >Learn more</a
          >
        </div>
        <span @click="paymentsCheck()">
          <DefaultToggle size="large" v-model="leagueSettings.payments"
        /></span>

        <div class="no-account" v-if="showFetchAccountsButton">
          <StandardSolidButton
            class="mt-3"
            title="Fetch connected accounts"
            :desktopFluidity="true"
            :fluid="false"
            @click="checkForConnectedAccounts()"
          />
        </div>
        <div
          class="placeholder"
          v-if="!showFetchAccountsButton && leagueSettings.payments == false"
        ></div>
        <div
          class="accounts"
          v-if="connectedAccounts.length > 0 && leagueSettings.payments"
        >
          <select
            name=""
            id=""
            v-model="tour.connectedAccountId"
            :class="{ disabled: tour.connectedAccountId == null }"
          >
            <option :value="null">Choose connected account</option>
            <option
              :value="account.id"
              v-for="account in connectedAccounts"
              :key="account.id"
            >
              {{ account.name }}
            </option>
          </select>
        </div>
      </div>
    </section>
    <section class="nav-buttons">
      <div class="tip">
        <span>
          <Tip />
        </span>

        <p>
          Everything can be edited in the Manager after your event has been
          saved.
        </p>
      </div>
      <StandardBorderedButton
        title="Back"
        :fluid="false"
        :desktopFluidity="true"
        :backwardsIcon="true"
        @click="$router.go(-1)"
      />
      <StandardSolidButton
        class="forwards"
        title="Next"
        :fluid="false"
        :forwardIcon="true"
        :desktopFluidity="true"
        @click="goNext()"
      />
    </section>

    <section class="start-over" @click="$refs.resetmodal.open()">
      Reset and start over
    </section>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="resetmodal"
      hide-close-button
    >
      <h3>Reset</h3>
      <p>
        This will remove any information you have added and take you back to the
        start.
      </p>

      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="$refs.resetmodal.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Reset"
          :fluid="false"
          @click="resetAndClose('resetmodal')"
        />
      </div>
    </sweet-modal>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="noaccountsfound"
      hide-close-button
    >
      <h3>How to set up direct payments</h3>
      <p>
        To take advantage of our revolutionary direct payment feature you first
        need to create an account through Stripe and connect it to your Tjing
        account.
      </p>
      <p>
        You can set this up after you have finished building or you can set up
        now from your profile. It’s up to you. If you decide to set up now then
        when you’re done come back and hit the fetch connected account button
        and choose the account to use.
      </p>

      <div class="buttons">
        <StandardBorderedButton
          class="forwards"
          title="Set up now"
          :fluid="false"
          @click="goToProfile()"
        />
        <StandardSolidButton
          title="Set up later"
          :fluid="false"
          @click="setupLater()"
        />
      </div>
    </sweet-modal>
  </main>
</template>

<script>
import DefaultToggle from "@/components/UIElements/DefaultToggle";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import FeatureVerifier from "@/assets/icons/FeatureVerifier";
import FeatureSignature from "@/assets/icons/FeatureSignature";
import FeaturePayment from "@/assets/icons/FeaturePayment";
import Tip from "@/assets/icons/Tip";

export default {
  name: "LeagueFeatures",
  data() {
    return {
      signature: true,
      verifier: true,
      payments: true,
      connectedAccounts: [],
      showFetchAccountsButton: false,
      showNoAccountsFoundModal: false,
      erronousSections: [],
    };
  },
  components: {
    DefaultToggle,
    FeatureVerifier,
    FeatureSignature,
    StandardSolidButton,
    StandardBorderedButton,
    FeaturePayment,
    Tip,
  },
  watch: {
    "tour.connectedAccountId": function (newVal) {
      this.removeError("payments");
    },
    "leagueSettings.payments": function (newVal) {
      if (newVal == false) {
        this.removeError("payments");
        this.$store.dispatch("updateConnectedAccountId", null);
      }
    },
  },
  computed: {
    leagueSettings() {
      return this.$store.getters.leagueSettings;
    },
    tour() {
      return this.$store.getters.tour.tour;
    },
    oneOffSettings() {
      return this.$store.getters.tour.oneOffSettings;
    },
  },
  methods: {
    setupLater() {
      this.$refs.noaccountsfound.close();
      this.showFetchAccountsButton = false;
      document.body.style.overflow = null;
      document.body.style.height = null;
      this.$store.dispatch("updateLeagueSettings", {
        property: "payments",
        bool: false,
      });
    },
    goToProfile() {
      this.showFetchAccountsButton = true;
      this.$refs.noaccountsfound.close();
      document.body.style.overflow = null;
      document.body.style.height = null;
      window.open("/profile", "_blank");
    },
    paymentsCheck() {
      if (!this.leagueSettings.payments && this.connectedAccounts.length == 0) {
        this.$refs.noaccountsfound.open();
      } else {
        this.showFetchAccountsButton = false;
      }
    },
    async checkForConnectedAccounts() {
      const connectedAccounts = await this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            me {
              connectedAccounts {
                id
                name
                createdAt
              }
            }
          }
          `,
        },
      });

      this.connectedAccounts = connectedAccounts.data.data.me.connectedAccounts;

      if (
        this.connectedAccounts.length == 0 &&
        this.showFetchAccountsButton == true
      ) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "No accounts found",
          type: "error",
        });
      } else if (this.connectedAccounts.length > 0) {
        this.showFetchAccountsButton = false;
      }
    },
    resetAndClose(modalname) {
      this.$refs[modalname].close();

      this.$store.dispatch("resetBuilderState");
      this.$router.push({ name: "builder" });
    },
    markErrors() {
      if (
        this.leagueSettings.payments &&
        this.tour.connectedAccountId == null
      ) {
        this.erronousSections.push("payments");
      }
    },
    removeError(error) {
      this.erronousSections = this.erronousSections.filter((e) => e != error);
    },
    goNext() {
      this.markErrors();

      if (this.erronousSections.length > 0) {
        this.$scrollTo("#" + this.erronousSections[0]);
      } else {
        this.$store.dispatch("updateBoolOnEvent", {
          eventIndex: 0,
          property: "isVerifyMode",
          value: this.leagueSettings.verifier,
        });
        this.$store.dispatch("updateBoolOnEvent", {
          eventIndex: 0,
          property: "isResultSigningEnabled",
          value: this.leagueSettings.signature,
        });
        this.$router.push({ name: "leaguesoptional" });
      }
    },
  },
  beforeMount() {
    this.checkForConnectedAccounts();
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.start-over {
  margin-top: 64px;
  color: $strom;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  color: $dusk;
}
.nav-buttons {
  margin-top: 22px;
  margin-right: 15px;
  margin-left: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .tip {
    padding: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: $sunrisemist;
    border: 1px solid #ffd97f;
    border-radius: 6px;
    margin-bottom: 24px;
    width: 100%;
    svg {
      height: 18px;
      width: 14px;
      margin-right: 8px;
    }

    p {
      display: inline;
      margin: 0;
      font-size: 14px;
    }
  }
  button {
    width: 47%;
  }
}

.foot {
  margin-top: 32px;
  color: $twilight;
  font-size: 14px;
}

.features {
  padding: 15px;

  > div {
    background: white;
    padding: 32px 30px 38px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    border-radius: 6px;
    border: 1px solid $sleet;
  }

  h3 {
    font-size: 20px;
    margin-top: 18px;
    margin-bottom: 16px;
  }
  p {
    text-align: center;
    font-size: 16px;
    margin: 0;
  }
  .links {
    margin-top: 16px;
  }
  a {
    font-size: 14px;
    color: $ocean;

    &:hover {
      text-decoration: underline;
    }
  }
  .default-toggle {
    margin-top: 32px;
  }
  .feature-icon {
    height: 54px;
    width: auto;
  }
  .feature-icon-small {
    height: 46px;
    width: auto;
  }
  .placeholder {
    display: none;
    height: 72px;
  }

  .no-account {
    font-size: 14px;
    margin-top: 22px;
  }
  .accounts {
    margin-top: 24px;
    width: 100%;

    select {
      width: 100%;
      margin-bottom: 0;

      &.disabled {
        color: $blizzard;

        option {
          color: $midnight;
        }
      }
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  h1 {
    font-size: 32px;
    @include Gilroy-Bold;
    text-align: center;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    text-align: center;
  }
}

.progress {
  margin-top: 32px;
  background: none;
  padding: 0 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }

  .stage {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-right: 12px;
  }
  .max {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-left: 12px;
  }
  .bar {
    width: 100%;
    background: $fog;
    height: 8px;
    border-radius: 4px;

    span {
      width: 75%;
      background: $ocean;
      display: flex;
      height: 8px;
      border-radius: 4px;
    }
  }
}
.links {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0 30px;
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .features {
    display: flex;
    justify-content: space-between;
    > div {
      width: 32%;
      padding: 32px 15px 38px 15px;
    }

    .payments {
      justify-content: flex-start;
    }

    .feature-icon-small {
      height: 32px;
      margin-top: 14px;
    }

    .links {
      padding: 0px;
    }
  }
  .progress {
    justify-content: center;
    .bar {
      width: 50%;
    }
  }

  .wrapper {
    padding: 0 20px;
  }
}

@media (min-width: 1200px) {
  .start-over {
    font-size: 16px;
  }
  .nav-buttons {
    margin-top: 64px;

    .tip {
      width: auto;
      order: 2;
      padding: 0 15px;
      display: flex;
      align-items: center;
      margin: 0;
      p {
        font-size: 16px;
      }
      svg {
        margin-right: 8px;
        margin-bottom: 2px;
      }
    }
    .backward {
      order: 1;
      width: auto;
    }
    .forwards {
      order: 3;
    }
  }

  .features {
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    .verifier {
      width: 31%;
      border-radius: 10px;
      padding-top: 48px;
      padding-bottom: 58px;
      svg {
        height: 80px;
      }
      h3 {
        font-size: 32px;
        margin-top: 32px;
        margin-bottom: 24px;
      }
      p {
        font-size: 18px;

        margin-bottom: 18px;
      }
      a {
        @include Gilroy-Medium;
        font-size: 16px;
        color: $ocean;
        margin-bottom: 24px;
      }
    }
    .signature {
      width: 31%;
      border-radius: 10px;
      padding-top: 48px;
      padding-bottom: 58px;
      svg {
        height: 80px;
      }
      h3 {
        font-size: 32px;
        margin-top: 32px;
        margin-bottom: 24px;
      }
      p {
        font-size: 18px;

        margin-bottom: 18px;
      }
      a {
        @include Gilroy-Medium;
        font-size: 16px;
        color: $ocean;
        margin-bottom: 24px;
      }
    }
    .payments {
      width: 31%;
      border-radius: 10px;
      padding-top: 60px;
      padding-bottom: 35px;
      transition: all ease 0.3;

      &.error {
        box-shadow: 0 0 0 2px $dusk;
      }
      .links {
        justify-content: center;
      }
      .placeholder {
        display: flex;
        height: 72px;
      }
      svg {
        height: 56px;
      }
      h3 {
        font-size: 32px;
        margin-top: 43px;
        margin-bottom: 24px;
      }
      p {
        font-size: 18px;
        margin-bottom: 18px;
      }
      a {
        @include Gilroy-Medium;
        font-size: 16px;
        color: $ocean;
        margin-bottom: 24px;
      }
      .feature-icon-small {
        margin-top: 0px;
      }
      .accounts {
        width: 100%;
        select {
          width: 100%;
          margin-top: 0px;
          margin-bottom: 0;
        }
      }
    }
  }
  .progress {
    margin: auto;
    margin-top: 92px;
    width: 300px;
    padding: 0;
  }
  .header {
    h1 {
      font-size: 64px;
      line-height: 64px;
      margin-top: 58px;
      margin-bottom: 0;
    }
    p {
      font-size: 18px;
      margin-top: 24px;
    }
  }
}
</style>
